<template>

  <div class="mainLogin">
    <div class="title" style='color: #fff; z-index: 1; width: 100%; height: calc(95vH) !important;'>
      <div style='float: top;'>
        <div>
           <center>
        
          <h1 style='padding-top: 10pt; font-size: 32pt;'>Login to {{ this.myTitle }}</h1>  
          </center>        
        </div>
      </div>
    
    <div style='display: flex; width: 100%; float: top;'>
      <form  name='loginForm' style='width: 100%; float: top;'>
      <center>
      <br/>
      <br/>
      <table width=25%>
        <tr><td colspan="2">
          <select class='menu select' v-model="accessPointID" >
          <option class='menu-item' v-for="(x) in accessPoints" :key="x.id" :value="x.id" v-bind:selected="x.id === accessPointID" >
           <div class='menu-item'> {{ x.name}} [{{ x.id}}]</div>
          </option>
          </select>
        </td></tr>
        <tr v-show="! disabled">
            <td>&nbsp;</td>
            <td style="height: 40pt;">&nbsp;</td>
        </tr>
        <tr v-show="! disabled">
        
         <td><div class="lb">LOGIN</div>
        <td class="loginInp"><InputText :border="true" autoComplete="username" @enter="submit()" v-model='user.login' /></td>
        </tr> 
        <tr v-show="! disabled">
        <td><div class="lb">PASSWORD</div>
        <td class="loginInp">
            <InputSecret @enter="submit()" autoComplete="current-password" :style="'background-color: transparent !important;'" v-model='user.password' /></td>
      </tr>
      </table>
      <button class="button is-dark down" :disabled="loginDisabled" type="button" @click="submit()">Login</button>
      
      </center>
      </form>
      </div>
    </div>

    <div style='position: absolute; bottom: 0; text-align: right; width: 100%; padding-right: 20pt; font-size: 9pt;'>
   {{getCopyright()}}
   </div>
  </div>
</template>
<script>
// import axios from 'axios';
import {HTTP, accessPointAPI, authAPI, showError} from '../variables.js';
import { getCopyright } from '../utils.js';
import { getAppStyle, setDarkMode, initAppMode } from '@/AppStyle.js';
import InputText from '@/components/inputElements/InputText';
import InputSecret from '@/components/inputElements/InputSecret';
import Label from '@/components/Label';

var selectedAccessPoint = { auth: "", id: 1 };
export default {
  name: 'app',
  components : {
    InputText, InputSecret
  },
  data () { 
    return {
      selectedAccessPoint,
      accessPointID: 1,
      accessPoints: [],
      loginPage : "",
      getCopyright,
      myURL: window.location.href,
      disabled: ( selectedAccessPoint.auth != 'internal'),
      loginDisabled: ( !( selectedAccessPoint.id > 0 )),
      loadingActive: false,
      user : {
        login: '',
        password: '',
        tenant: '',
        accessPoint: ''
      }
      
      }
    },
  created()
  {
	let time = new Date().getTime();
	if ( ! sessionStorage.caching )
	{
		sessionStorage.caching = 0;
	}
	if ( sessionStorage.caching+86400*1000 < time )
	{
		sessionStorage.caching = time;
		window.location.reload(true);
	}
    sessionStorage.ident=this.$route.params.ident; // store ident
    
    let that = this;
    HTTP.get( accessPointAPI+"/public/findByIdent/"+this.ident())
                .then( response => { 
                    this.accessPoints = response.data;
                    this.accessPoints.sort( (a,b)=>a.sorting-b.sorting)
                    this.selectedAccessPoint = this.accessPoints[0];
                    this.accessPointID=this.selectedAccessPoint.id;
                    this.checkAccessPoint();
                }).catch(e => {
			     console.log( JSON.stringify(e))
			     if ( (e.message).indexOf("timeout") >= 0)
			     {
					 showError(that.$toast, "Login not permitted (yet), please write to support@nextadsystem.com", "");
				 }
				 else
				 {
					 showError(that.$toast, "loading access points", e);
				 }
                 that.loginDisabled = true;                 
              }) ;
    initAppMode();
  }, 
    computed: {
    console: () => console,
    window: () => window,
   },
  watch:
  {
    accessPointID: function()  { console.log(this.accessPointID); this.checkAccessPoint(); }
  },
  methods: {
        ident() { return sessionStorage.ident; },
        submit(){
            let ap = this.accessPoints.find(a => a.id === this.accessPointID);
			this.startLoader();
			if ( ap )
			{
				sessionStorage.tenantId = ap.tenantId;
				sessionStorage.accessPointId = this.accessPointID;
				sessionStorage.numberFormat="0,000.00";
			}
			
			let login = {
                               "xusr": this.user.login,
                               "xpw": this.user.password,
                               "xurl": this.myURL
                            };
			
            HTTP.post( authAPI+"/url/"+ap.tenantId+"/"+ap.id, login)
                .then( response => {
                        this.loginpage = response.data;
                        sessionStorage.welcome = this.loginpage;
                        this.loadingActive = false;
               			this.loader.hide();
                        window.location.href = this.loginpage;
						
                }).catch(e => {
               this.loadingActive = false;
               this.loader.hide();
              }) ;
          
        },
        checkAccessPoint(){
            
            this.selectedAccessPoint = this.accessPoints.find(p => p.id == this.accessPointID);
            this.disabled= ( this.selectedAccessPoint.openid);

            this.loginDisabled = ( ! ( this.selectedAccessPoint.id > 0) )
            //console.log(this.selectedAccessPoint.auth);
        },
        startLoader()
	    {
	    	if ( !this.loadingActive)
	      	{
		      	this.loadingActive = true;
		      	this.loader = this.$loading.show({
		                    // Optional parameters
		                    container: this.$refs.formContainer,
		                    canCancel: true,
		                    programmatic: false,
		                    onCancel: this.onCancel,
		                    color: '#000000',
						    loader: 'dots',
						    width: 64,
						    height: 64,
						    active: true,
						    backgroundColor: '#ffffff',
						    opacity: 0.5,
						    zIndex: 999,
		                });
		    }
	    }
    }
}
</script>
<style lang="scss" scoped>
  .org-description {
    margin-top: 50px;
  }
  .menu-item {
	color: #212529;
  padding: .25rem 1.5rem;
  text-align: left;
	transition: color .15s ease-in-out,background-color .95s ease-in-out,border-color .95s ease-in-out,box-shadow .15s ease-in-out;
}
.title {
  background-image: linear-gradient( rgba(40,0,220,.5), rgba(0,220,120,.9) );
}
.mainLogin {
    display: block;
    float: top;
	height: 100% !important;
}
.loginInp {
  background-color: transparent;
  font-weight: normal;
  color: #000;
}
.lb {
  color: #000;
  font-weight: bold;
  padding-top: 8pt;
  font-size: 12pt;
}
.menu {
	background-color: #fff;
	background-clip: padding-box;
	border: 1px solid rgba(0,0,0,.15);
	border-radius: .25rem;
	color: #212529;
	cursor: pointer;
	display: flex;
    padding: 10pt;
  flex-direction: column;
  text-align: center;
	font-size: 1rem;
	list-style: none;
	margin: .125rem 0 0;
  padding: .5rem 0;
  width: 100%;
	// position: absolute;
	// text-align: left;
}
.down {margin-top: 10pt;}
  .login {
    margin-top: 30pt;
  }
</style>